<template>
  <div class="order-page">
    <div class="title">{{ $t('xia-dan') }}</div>
    <div class="sub-title">{{ $t('shou-huo-di-zhi') }}</div>
    <div class="address-box flex-center-between" @click="changeAddress">
      <div>
        <div class="name">
          {{ addressInfo.FullName }} {{ addressInfo.Phone }}
        </div>
        <div class="address">
          {{ addressInfo.Country }} {{ addressInfo.Province }}
          {{ addressInfo.City }} {{ addressInfo.Address }}
        </div>
      </div>
      <div>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="shop-box">
      <div class="shop" v-for="(item, index) in shoppingList" :key="index">
        <div class="check-line">
          <Checkbox
            v-model="item.checked"
            checked-color="#9d48f7"
            @click="clickShop(item, index)"
            icon-size="16px"
          >
            {{ item.Shop.Name }}
            {{ $t('gong-ji') }}
            {{ item.goodsList.length }}
            {{ $t('jian-shang-pin') }})</Checkbox
          >
        </div>
        <div class="goods-list">
          <div
            class="goods flex-center"
            v-for="(good, i) in item.goodsList"
            :key="i"
          >
            <div class="check">
              <Checkbox
                v-model="good.checked"
                checked-color="#9d48f7"
                @click="clickGoods(good, i, index)"
                icon-size="16px"
              ></Checkbox>
            </div>
            <div class="img">
              <img :src="good.MainImage" alt="" />
            </div>
            <div class="flex-1 info">
              <div class="name">
                {{
                  good.ProductTranslation && good.ProductTranslation[0]
                    ? good.ProductTranslation[0].Name
                    : ''
                }}
              </div>
              <div class="price">${{ good.optionPrice || good.SalePrice }}</div>
              <div class="option">
                {{ $t('gui-ge') }}
                {{ good.optionName }}
              </div>
            </div>
            <div class="number">
              <el-input-number v-model="good.count" :min="1"></el-input-number>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="title">{{ $t('ding-dan-hui-zong') }}</div>

    <div class="total-box">
      <div class="line">
        <div class="label">{{ $t('shang-pin-jin-e') }}</div>
        <div class="value">${{ totalMoney }}</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('zhe-kou') }}</div>
        <div class="value">-$0.00</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('shui-shou') }}</div>
        <div class="value">+$0.00</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('yun-fei') }}</div>
        <div class="value">+$0.00</div>
      </div>
      <div class="line total">
        <div class="label">{{ $t('he-ji') }}</div>
        <div class="value">${{ totalMoney }}</div>
      </div>
    </div>

    <div class="title">{{ $t('zhi-fu-fang-shi') }}</div>

    <div class="pay-list">
      <div class="pay flex-center" v-for="(item, i) in payList" :key="i">
        <img :src="item.img" alt="" />
        <div class="flex-1">
          <div class="name">
            {{ item.name }}
            <span class="gray" :class="i == 0 ? 'price' : ''"
              >({{ i == 0 ? `$${userMoney}` : item.desc }})</span
            >
          </div>
        </div>
        <div class="">
          <el-radio
            :disabled="i > 0"
            class="hide-label"
            v-model="payMethods"
            :label="i"
            name="pay"
          ></el-radio>
        </div>
      </div>
    </div>

    <div class="submit-line flex-center-between">
      <div>
        {{ $t('xuan-ze') }}
        {{ chooseList.length }}
        {{ $t('jian') }}
      </div>
      <div>
        <el-button @click="submit">
          {{ $t('ti-jiao-ding-dan') }}
          ${{ totalMoney }}
        </el-button>
      </div>
    </div>

    <bottomLink></bottomLink>

    <el-dialog
      :title="$t('shu-ru-zhi-fu-mi-ma')"
      :visible.sync="showPopup"
      width="400px"
      class="pay-dialog"
    >
      <div class="pay-box">
        <div class="label">{{ $t('qing-shu-ru-nin-de-zhi-fu-mi-ma') }}</div>
        <passwordKeyboard v-model="password"></passwordKeyboard>
        <div class="btn">
          <el-button
            type="primary"
            :disabled="password.length != 6"
            @click="doPay"
            >{{ $t('que-ren') }}</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('xuan-ze-song-huo-di-zhi')"
      :visible.sync="showDialog"
      width="600px"
      class="address-dialog"
    >
      <div class="address-list">
        <div
          class="address box"
          v-for="(item, i) in addressList"
          :key="i"
          @click="chooseAddress(item)"
          :class="addressInfo.ID == item.ID ? 'active' : ''"
        >
          <div class="name">{{ item.FullName }} {{ item.Phone }}</div>
          <div class="address">
            {{ item.Country }} {{ item.Province }} {{ item.City }}
            {{ item.Address }}
          </div>
        </div>

        <div class="box add" @click="showAdd">{{ $t('xin-zeng-di-zhi') }}</div>
      </div>
    </el-dialog>

    <addressDialog
      :showDialog="showAddDialog"
      @close="showAddDialog = false"
      @init="initAddress(false)"
    ></addressDialog>
  </div>
</template>
<script>
import bottomLink from '@/components/bottomLink'
import passwordKeyboard from '@/components/password'
import addressDialog from '../userInfo/address.vue'
import {
  getWalletInfo,
  addOrder,
  orderPay,
  shippingAddressList
} from '@/api/user'
import { Checkbox } from 'vant'
export default {
  components: {
    bottomLink,
    Checkbox,
    passwordKeyboard,
    addressDialog
  },
  data() {
    return {
      password: '',
      addressInfo: {},
      payMethods: 0,
      showPopup: false,
      chooseList: [],
      shoppingList: [],
      goodsList: [],
      checkAll: true,
      showDialog: false,
      showAddDialog: false,
      orderId: '',
      addressList: [],
      payList: [
        {
          name: this.$t('yu-e'),
          img: require('@/assets/imgs/icon-pay1.png'),
          desc: '',
          checked: true
        },
        {
          name: this.$t('bei-bao'),
          img: require('@/assets/imgs/icon-pay2.png'),
          desc: this.$t(
            'gai-zhi-fu-fang-shi-shang-wei-dui-nin-suo-zai-de-di-qu-kai-fang'
          )
        },
        {
          name: this.$t('visa'),
          img: require('@/assets/imgs/icon-pay3.png'),
          desc: this.$t(
            'gai-zhi-fu-fang-shi-shang-wei-dui-nin-suo-zai-de-di-qu-kai-fang-0'
          )
        },
        {
          name: this.$t('wan-shi-da-ka'),
          img: require('@/assets/imgs/icon-pay4.png'),
          desc: this.$t(
            'gai-zhi-fu-fang-shi-shang-wei-dui-nin-suo-zai-de-di-qu-kai-fang-1'
          )
        }
      ],
      userMoney: ''
    }
  },
  computed: {
    totalMoney() {
      return this.chooseList
        .reduce((a, b) => {
          return a + (b.optionPrice || b.SalePrice) * b.count
        }, 0)
        .toFixed(2)
    }
  },
  mounted() {
    this.initMoney()
    this.initAddress(true)
    let data = sessionStorage.getItem('orderData')
    if (data) {
      this.shoppingList = JSON.parse(data)
      this.chooseList = []
      this.shoppingList.forEach((v) => {
        v.checked = true
        v.goodsList.forEach((val) => {
          val.checked = true
        })
        this.chooseList = this.chooseList.concat(v.goodsList)
      })
    }
  },
  methods: {
    initAddress(getDefault) {
      shippingAddressList().then((res) => {
        let list = res.data.Items
        this.addressList = list
        if (getDefault) {
          if (list && list.length > 0) {
            let index = list.findIndex((v) => {
              return v.IsSelected == 1
            })
            if (index === -1) {
              index = 0
            }
            this.addressInfo = list[index] || {}
          }
        }
      })
    },
    initMoney() {
      getWalletInfo().then((res) => {
        let list = res.data.Items[0]
        this.userMoney = list.Balance
      })
    },
    clickShop(item, index) {
      let checked = item.checked
      this.shoppingList[index].goodsList.forEach((v) => {
        v.checked = checked
      })
      this.$forceUpdate()
      this.resetCheckList()
    },
    clickGoods(data, i, index) {
      let checked = data.checked

      let goodsList = this.shoppingList[index].goodsList
      let size = goodsList.filter((v) => {
        return v.checked
      }).length
      if (size == goodsList.length) {
        this.shoppingList[index].checked = true
      } else {
        this.shoppingList[index].checked = false
      }
      this.$forceUpdate()
      this.resetCheckList()
    },
    resetCheckList() {
      this.chooseList = []
      this.shoppingList.forEach((v) => {
        v.goodsList.forEach((val) => {
          if (val.checked) {
            this.chooseList.push(val)
          }
        })
      })
    },
    submit() {
      if (this.chooseList.length === 0) {
        this.$message.warning(this.$t('qing-gou-xuan-shang-pin'))
        return
      }
      let list = []
      this.shoppingList.forEach((v) => {
        v.goodsList.forEach((val) => {
          let param = {
            BusinessProductID: val.ID,
            BusinessProductSpecificationsRelationID: val.optionId || null,
            Number: val.count
          }
          list.push(param)
        })
      })
      addOrder({
        ShippingAddressID: this.addressInfo.ID,
        Lists: list
      }).then((res) => {
        if (res.code == 0) {
          this.orderId = res.data
          this.showPopup = true
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    closeDialog() {
      this.showPopup = false
    },
    doPay() {
      orderPay({
        OrderID: this.orderId,
        Password: parseInt(this.password)
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('zhi-fu-cheng-gong'))
          this.showPopup = false
          this.$router.push({
            name: 'userInfoOrderList'
          })
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    changeAddress() {
      this.showDialog = true
    },
    showAdd() {
      this.showAddDialog = true
    },
    chooseAddress(data) {
      this.addressInfo = data
      this.showDialog = false
      this.showAddDialog = false
    }
  }
}
</script>